import service from '@/utils/request'; // @Tags UserSubscription
// @Summary 创建UserSubscription
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.UserSubscription true "创建UserSubscription"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /userSubscription/createUserSubscription [post]

export var createUserSubscription = function createUserSubscription(data) {
  return service({
    url: "/userSubscription/createUserSubscription",
    method: 'post',
    data: data
  });
}; // @Tags UserSubscription
// @Summary 删除UserSubscription
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.UserSubscription true "删除UserSubscription"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /userSubscription/deleteUserSubscription [delete]

export var deleteUserSubscription = function deleteUserSubscription(data) {
  return service({
    url: "/userSubscription/deleteUserSubscription",
    method: 'delete',
    data: data
  });
}; // @Tags UserSubscription
// @Summary 删除UserSubscription
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除UserSubscription"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /userSubscription/deleteUserSubscription [delete]

export var deleteUserSubscriptionByIds = function deleteUserSubscriptionByIds(data) {
  return service({
    url: "/userSubscription/deleteUserSubscriptionByIds",
    method: 'delete',
    data: data
  });
}; // @Tags UserSubscription
// @Summary 更新UserSubscription
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.UserSubscription true "更新UserSubscription"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /userSubscription/updateUserSubscription [put]

export var updateUserSubscription = function updateUserSubscription(data) {
  return service({
    url: "/userSubscription/updateUserSubscription",
    method: 'put',
    data: data
  });
}; // @Tags UserSubscription
// @Summary 用id查询UserSubscription
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.UserSubscription true "用id查询UserSubscription"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /userSubscription/findUserSubscription [get]

export var findUserSubscription = function findUserSubscription(params) {
  return service({
    url: "/userSubscription/findUserSubscription",
    method: 'get',
    params: params
  });
}; // @Tags UserSubscription
// @Summary 分页获取UserSubscription列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取UserSubscription列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /userSubscription/getUserSubscriptionList [get]

export var getUserSubscriptionList = function getUserSubscriptionList(params) {
  return service({
    url: "/userSubscription/getUserSubscriptionList",
    method: 'get',
    params: params
  });
};